<template>
  <div>
    <el-menu class="personal-navbar flex flex_acenter flex_jcbetween" mode="horizontal" >
      <div class="pointer flex flex_acenter personal-back-home" @click="backto">
        <i class="icon-home-management fontsize_16 color_222"><span class="path1"></span><span class="path2"></span><span class="path3"></span></i>
        <span class="padding_left6 fontsize_14 color_222">{{$t('lang.managementpage')}}</span>
      </div>
      <div class="personal-navtabs flex flex1 flex_center">
        <div :class="{'active':tabsIndex==tind}" v-for="(item,tind) in tabs" :key="item.value" @click="changeTab(tind,item)">
          <div class="fontsize_16 personal-navtabs-item" :class="{'active color_222':tabsIndex==tind,'color_26':tabsIndex!=tind}">{{$t('lang.'+item.langlabel) || item.name}}</div>
        </div>
      </div>
    </el-menu>
  </div>
</template>

<script>
import logoPic from '@/assets/logo@3x.png'
import { mapMutations, mapState } from 'vuex'
export default {
  components: {
  },
  data() {
    return {
      logosrc:logoPic,
      tabs:[
        {name: "支付与订单", langlabel:"paymentandorder",value:"paymentandorder",url:"/personal/order"},
        {name: "个人资料", langlabel:"personaldata",value:"personaldata",url:"/personal"},
        {name: "账户安全", langlabel:"accountsecurity",value:"accountsecurity",url:"/personal/account"}
      ],
      tabsIndex:0,
    }
  },
  computed: {
  },
  mounted() {
    let {fullPath} = this.$route;
    let curentUrl = this.tabs[this.tabsIndex].url;
    if(fullPath !== curentUrl) {
      let targetInd = this.tabs.findIndex(v => fullPath==v.url);
      if(!targetInd) return;
      this.changeTab(targetInd,this.tabs[targetInd],false);
    }
  },
  methods: {
    backto() {
      this.$router.push('/home');
      // this.$navBlank({
      //   path: '/home',
      //   pageId: "courseList",
      //   isBack:true
      // });
    },
    changeTab(index,item, isJump = true) {
      if(this.tabsIndex == index) return;
      // if(item.value === "paymentandorder") {
      //   this.$message.info("暂未开放！");
      //   return;
      // }
      this.tabsIndex = index;
      isJump && this.$router.push({path:item.url})
    },
  }
}
</script>

<style  lang="less" scoped>
.personal-navbar {
  position: relative;
  z-index: 999;
  height: 60px;
  // line-height: 60px;
  background: #FFFFFF;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.12);
  border-bottom: 0;
  padding:0 24px 0 18px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  .personal-navtabs-item {
    position: relative;
    line-height: 60px;
    padding: 0 24px;
    cursor: pointer;
    &:hover {
      font-weight: bolder;
    }
    &.active {
      font-weight: bolder;
    }
    &.active::after {
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%) translateY(0);
      content: "";
      display: block;
      width: 44px;
      height: 2px;
      background-color: #e74362;
    }
  }
}
.personal-back-home {
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
  border-radius: 13px;
  padding: 0 8px;
  height: 26px;
  line-height: 26px;
  &:hover {
    background-color: #F5F5F5;
  }
}
</style>

